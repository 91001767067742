import React from 'react'
import { DataElementLocations } from '@eig-builder/core-utils/helpers/tagging-helper'
import IndexLayout from './terms-index.styles'
import { TERMS_INFO } from '../../../helpers/terms.constants'

const TermsIndex = () => (
  <IndexLayout>
    {TERMS_INFO.map((info) => (
      <IndexLayout.text>
        <IndexLayout.title
          key={info.id}
          data-element-location={DataElementLocations.BODY}
          data-element-label='terms.index.link'
          data-element-id={`sitey-terms-index-${info.id}`}
          target={`${info.linkOption}`}
          to={
            info.id === 'privacy-notice'
              ? 'https://newfold.com/privacy-center '
              : `/terms/${info.id}`
          }
        >
          {info.title}
        </IndexLayout.title>
        <IndexLayout.subtitle>{info.description}</IndexLayout.subtitle>
      </IndexLayout.text>
    ))}
  </IndexLayout>
)

export default TermsIndex
