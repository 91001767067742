import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TermsIndex from '../../components/terms/terms-index'

const Index = () => {
  return (
    <Layout>
      <SEO title='Terms' pageType='terms' flowType='none' />
      <TermsIndex />
    </Layout>
  )
}

export default Index
