import styled from 'styled-components'
import { theme, media } from '../../../helpers/theme'
import Link from 'gatsby-link'

const IndexLayout = styled.div`
  background-color: ${theme.colors.white};
  margin: 0 auto;
  padding: ${theme.space[6]}px ${theme.space[3]}px;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }
  ${media(1200)} {
    width: 1170px;
  }
`

const Text = styled.div`
  margin: ${theme.space[6]}px 0;
`

const Title = styled(Link)`
  transition: all 0.2s ease-in-out;
  color: ${theme.colors.font.green};
  font-size: ${theme.font.lg};
  font-weight: 400;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.font.darkGreen};
  }
`

const Subtitle = styled.p`
  color: ${theme.colors.font.gray};
  font-size: ${theme.font.base};
`

IndexLayout.text = Text
IndexLayout.title = Title
IndexLayout.subtitle = Subtitle
export default IndexLayout
